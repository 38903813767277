.App {
  text-align: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(10, 78, 162, 1) 35%,
    rgba(73, 146, 189, 1) 100%
  );
  min-height: 150vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: serif;
}

.subhead {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.links-row {
  display: flex;
  margin: auto;
}

.links-row > div {
  margin: 10px;
}
.link-block {
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 5px;
}

h6 {
  margin: 1em;
}
.left-col,
.right-col {
  /* flex: 1;
  align-content: center; */
}

.left-col {
  /* align-content: left;
  display: flex;
  flex-direction: column; */
  /* align-items:  */
}

.App-header h1 {
  margin: 5vh 0;
}

,
.App-header h2 {
  margin: 3.5vh 0;
}

.App-header h3 {
  margin: 2vh 0;
  background: black;
  padding: 1vh;
  /* border-radius: 5px; */
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

section {
  padding: 0 1.5vh 33vh 1.5vh;
  /* margin-bottom: 33vh; */
}

.about {
  max-width: 700px;
  background-color: #f8f9fa;
  color: #282c34;
  font-size: calc(10px + 1vmin);
  border-radius: 20px;
  padding: 20px 50px;

  box-shadow: 5px 5px 5px #333;
  margin: 2vh auto;
  position: relative;
  text-align: left;
}

.read-more-link {
  color: #022453;
  font-size: 90%;
}

h4,
.about-head {
  width: calc(100% - 200px);
  font-weight: 700;
  margin: 0.5em 0;
  color: #655f60;
}

.links iframe {
  flex: 1;
}
.photo {
  background-size: cover;
  border-radius: 25px;
  box-shadow: 5px 5px 5px #333;
}

.author-photo {
  background-image: url("/BackCoverPhotowithDog.jpg");
  height: 240px;
  width: 200px;
  float: right;
  margin: 10px;
  margin: -100px -40px 10px 10px;
}

.about.author-photo-salvio-fill {
  background-image: url("/john_salvio_500x375.jpg");
  background-size: cover;
  padding-bottom: 60%;
  transform: rotate(4.5deg);
  max-width: 100%;
  margin: 0 2vh;
}

.author-photo-salvio {
  background-image: url("/john_salvio_500x375.jpg");
  height: 185px;
  width: 250px;
  float: left;
  margin: 10px;
}
@media only screen and (min-width: 630px) {
  .author-photo {
    background-image: url("/BackCoverPhotowithDog.jpg");
    height: 300px;
    width: 186px;
    float: right;
    margin: -100px -60px 10px 10px;
  }

  .author-photo-salvio {
    background-image: url("/john_salvio_500x375.jpg");
    height: 375px;
    width: 500px;
    margin: -150px 10px 10px -110px;
  }
}

@media only screen and (min-width: 980px) {
  .author-photo {
    background-image: url("/BackCoverPhotowithDog.jpg");
    height: 400px;
    width: 246px;
    float: right;
    margin: -120px -100px 10px 10px;
  }
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

footer {
  position: fixed;
  height: 30vh;
  width: 100%;
  background-image: url("/base.png");
  bottom: 0;
  background-size: cover;
}
